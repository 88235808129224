@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .unregistered-text-container {
    width: 350px;
    font-size: 12pt;
    line-height: 1.25;
    margin-top: 28px;
  }

  .login-form {
    margin-top: 20vh;
    padding: 30px;
    background-color: $NHB_white;
    border-radius: 10px;
    box-shadow: 0 2px 10px -6px $NHB_gray_3;

    .login-text-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-wrap: nowrap;

      @media (max-width: #{$break-small}) {
        flex-wrap: wrap;
      }
    }

    .wordmark {
      width: 130px;
      height: 70px;
      margin-right: 40px;

      @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
        width: 100px;
        height: 53px;
      }
      @media (max-width: #{$break-small}) {
        margin-right: auto;
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $NHB_black;
      font-family: $font-book;
      font-size: 14pt;

      @media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
        font-size: 12pt;
      }
    }

    .login-button {
      display: inline-block;
      width: 100%;
      margin: 28px 0 10px;
    }
  }
}

@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.category-list {
  min-width: 14em;
  width: 14em;
  max-width: 14em;
  margin-right: 16px;
  min-height: 71vh;
  border-right: 1px solid $NHB_hilight_gray;
  padding-right: 15px;

  h4 {
    font-size: 14pt;
    margin-bottom: 24px;
    margin-left: 12px;
    color: $NHB_gray_1;
    font-family: $font-book;
  }

  li {
    width: 100%;
    font-size: 11pt;
    padding: 5px 5px 5px 12px;
    line-height: 25px;
    &:hover {
      background: $NHB_medium_gray;
    }
  }

  a {
    text-decoration: none;
    &:visited {
      color: $NHB_blue;
    }
  }

  @media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
    border: 1px solid $NHB_hilight_gray;
    border-radius: 8px;
    padding: 0px 0;
    margin: 15px 0 35px 0;
    width: 25em;
    max-width: 100vw;
    min-width: auto;
    min-height: auto;
    h4 {
      color: $metallic_gold;
      padding: 10px;
      margin: 0 0 10px;
      border-radius: 5px 5px 0 0;
      box-shadow: 0 0 $NHB_dark_black;
      border-bottom: 1px solid #b4975a59;
    }
    ul {
      margin: 0 14px;
      border-bottom: 1px;
      li {
        border-bottom: 1px solid #f4f0e7;
        span {
          border-left: 2px solid $NHB_gray_2;
          padding-left: 10px;
          margin-left: -15px;
        }
      }
    }
    h1 {
      font-size: 18pt;
      margin-top: 25px;
      border-left: 2px solid $metallic_gold;
      padding: 0 0 10px 10px;
      border-bottom: 1px solid $NHB_medium_gray;
    }
  }
}

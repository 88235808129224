@import "../../../styles/colors";
@import "../../../styles/fonts";
@import "../../../styles/media";

.ucop-table {
  width: 100%;
  display: block;
  max-width: 100vw;
  table {
    border-collapse: collapse;
    width: 100%;
    .no-content-table {
      text-align: center;
      color: $NHB_gray_1;
      font-size: 10pt;
    }
    tr:first-child {
      border-bottom: 1px solid $NHB_medium_gray;
    }
    tr th {
      border-right: 1px solid $NHB_white;
      background: $NHB_medium_gray;
      padding: 10px 10px 0px 10px;
      font-size: 10pt;
      -webkit-transition: all 1s ease 0s;
      -moz-transition: all 1s ease 0s;
      -o-transition: all 1s ease 0s;
      -ms-transition: all 1s ease 0s;
      transition: all 1s ease 0s;
      .header-filter {
        font-size: 10pt;
        font-family: $font-bold;
        background: none;
        border: none;
        width: 140px;
        text-align: left;
        display: block;
        padding: 0;
        margin-bottom: 5px;

        &:focus {
          outline: none;
        }
      }
      button.btn-close-tooltip {
        background: $NHB_blue;
        color: $NHB_white;
        border: none;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        padding: 2px;
        font-size: 7pt;
        position: relative;
        top: -2px;
        cursor: pointer;
      }
      input.filter-field {
        height: 25px;
        width: 80%;
        margin-right: 5px;
        border-radius: 5px;
        border: 1px solid $NHB_hilight_gray;
      }
    }
    th[scope="col"] {
      font-family: $font-bold;
      padding-bottom: 10px;
      text-align: left;
    }
    td {
      text-align: left;
      padding: 8px;
      font-size: 10pt;
      font-family: $font-book;
      min-height: 40px;
      line-height: 25px;
      height: 40px;
    }
    tr:nth-child(even) {
      background-color: $light_gray;
    }
    tr:hover {
      background-color: $NHB_light_blue;
      cursor: pointer;
    }
  }

  .skip-link {
    position: absolute;
    left: -99999em;

    &:focus {
      position: static;
    }
  }
}

.category-issue-list .results-counter {
  bottom: 0px;
  bottom: 0px;
  float: right;
  height: 30px;
}
.results-counter {
  text-align: left;
  color: $NHB_gray_1;
  font-size: 10pt;
  margin-bottom: 10px;
  clear: both;
}
@media (min-width: #{$break-medium}) and (max-width: #{$break-large - 1px}) {
  .ucop-table {
    overflow-x: auto;
    max-width: 70vw;
    display: block;
    table {
      td {
        text-align: left;
      }
      tr th {
        .header-filter {
          text-align: left;
        }
      }
    }
  }
}
@media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
  .results-counter {
    text-align: right;
    color: $NHB_gray_2;
    font-size: 10pt;
    position: relative;
    top: 20px;
    margin-bottom: 35px;
  }
  .category-issue-list .results-counter {
    top: -10px;
    margin-bottom: 0;
  }
}

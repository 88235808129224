@import "../../../styles/colors";
@import "../../../styles/fonts";
@import "../../../styles/media";

.pagination {
  //border-top: 1px solid $NHB_gray_5;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 10px 0;
  margin-top: 15px;
  button {
    cursor: pointer;
    color: $NHB_black;
    float: left;
    padding: 3px 7px;
    margin: 0px 2px;
    text-decoration: none;
    font-family: $font;
    font-size: 10pt;
    border: none;
    background: transparent;
    &:hover:not(.active) {
      background-color: $NHB_gray_5;
      background: transparent;
    }
  }
  button.active {
    cursor: auto;
    background-color: $NHB_blue;
    color: $NHB_white;
    border-radius: 5px;
  }
}

@import "../../styles/colors";
@import "../../styles/fonts";

.app-footer {
  display: flex;
  height: 60px;
  justify-content: flex-end;
  padding: 20px 25px 20px 25px;
  border-top: 1px solid $NHB_hilight_gray;
  background: $NHB_white;
  font-size: 11pt;
  font-family: $font-book;
}

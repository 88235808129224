@import "../../../styles/colors";
@import "../../../styles/fonts";

.user-management {
  .admin-table {
    .user-button-link,
    .user-button-link:visited {
      text-decoration: none;
    }

    .fa-user-times {
      color: $NHB_red;
    }
    .fa-user-edit {
      color: $NHB_black;
    }
  }

  .fa-user-times,
  .fa-user-edit {
    &:hover {
      color: $blue;
      cursor: pointer;
    }
  }
  .fa-user-edit.disabled {
    color: $NHB_gray_5;
    &:hover {
      cursor: not-allowed;
    }
  }

  .user-action-modal {
    label {
      margin-top: 30px;
    }
    &.delete-user h2 {
      color: $NHB_red;
    }
  }

  .email-address {
    font-family: $font-bold;
  }
}

@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.category-issue-list {
  .ucop-table table {
    a {
      display: block;
      color: $NHB_black;
      text-decoration: none;
      padding: 8px 0;
    }

    th {
      font-family: $font-med;
    }

    tr {
      padding: 0;
      background: none;
      border-bottom: 2px solid $NHB_medium_gray;
      &:first-child {
        border-bottom: 2px solid $NHB_gray_5;
        &:hover {
          background: none;
        }
      }
      &:last-child {
        border-bottom: 1px solid $NHB_gray_5;
      }

      &:hover {
        background-color: $NHB_light_blue;
      }
    }
  }
}
@media (max-width: #{$break-medium - 1}) and (min-width: #{$break-small}) {
}

@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.app-header-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $NHB_white;
  padding: 16px 40px;

  .ucop-wordmark {
    height: 48px;
    display: inline-block;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
      align-items: baseline;
      width: 100%;
      padding-bottom: 25px;
      position: relative;
      left: -20px;
      top: 10px;
    }
  }

  a {
    text-decoration: none;
  }

  h2 {
    font-size: 12pt;
    padding-left: 30px;
    color: $NHB_gray_2;
    font-family: $font-book;
    position: relative;
    top: 18px;
    @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
      padding-left: 20px;
      font-size: 12pt;
      width: 12em;
      top: 0;
    }
  }

  @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
    flex-wrap: wrap;
  }
}

.app-header-search-row {
  padding: 12px 28px 12px 28px;
  background-color: $NHB_medium_gray;
  display: flex;
  flex-direction: row-reverse;
}

@import "../../../styles/fonts";
@import "../../../styles/widget-colors";

.ucop-error-list {
  color: $error_list_fg;
  &:focus {
    outline: none;
  }

  h4 {
    font-family: $font-bold;
    font-size: 22px;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
    padding: 5px 20px;
  }

  li {
    margin: 8px 0;
    padding-top: 15px;
    font-size: 14px;
    padding-bottom: 6px;
  }
}

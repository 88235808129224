@import "../../styles/colors";
@import "../../styles/media";

.search-bar {
  display: flex;
  align-items: center;
  input {
    height: 40px;
    width: 18em;
    padding: 8px 16px;
    font-size: 11pt;
    border-radius: 4px 0 0 4px;
    border: 1px solid $NHB_gray_5;
    border-right: none;
  }
  button {
    height: 40px;
    width: 48px;
    border: $NHB_blue;
    background-color: $NHB_blue;
    color: $NHB_white;
    border-radius: 0 4px 4px 0;
  }
  @media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
    width: 305px;
    margin: 0 auto;
  }
}

@import '../../../styles/colors';
.ucop-spinner { text-align: center;

  .ucop-spinner-contents {
    div {
      margin: 0 2px;
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: $NHB_gray_3;
      border-radius: 50%;
      animation: ucop-spinner-throb 3s infinite;
    }
    div:nth-of-type(1) {
      animation-delay: 0s;
    }
    div:nth-of-type(2) {
      animation-delay: 1s;
    }
    div:nth-of-type(3) {
      animation-delay: 2s;
    }
    &.light div {
      background-color: $NHB_white;
    }
    &.small div {
      width: 6px;
      height: 6px;

    }
  }
}

@keyframes ucop-spinner-throb{
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }
}

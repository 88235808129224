@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.modal .button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  button {
    margin: 0 10px;
    width: 30%;
    position: static;
    transform: none;
    top: 0;
    left: 0;
  }
  .cancel-button {
    background-color: $NHB_white;
    border: 1px solid $NHB_blue;
    color: $NHB_blue;

    .ucop-spinner-contents div {
      background-color: $NHB_blue;
    }

    &:hover {
      background-color: $warm_gray_1;
      border: 1px solid $blue;
      color: $blue;
    }
  }
}

.admin-table {

  .fas {
    padding-right: 10px;
    display: inline;
    color: $NHB_black;
    &:hover {
      color: $blue;
      cursor: pointer;
    }
  }

  .add-item-btn {
    float: right;
    padding: 10px 15px;
    background: $NHB_white;
    margin-bottom: 15px;
    border-radius: 3px;
    margin-top: 0;
    color: $NHB_green_medium;
    border: 1px solid $NHB_green_light;

    &:hover {
      cursor: pointer;
      background-color: $NHB_green_medium;
      color: $NHB_white;
    }

    .btn-text {
      font-family: $font-med;
      font-size: 11pt;
      padding: 0px 0px 0 10px;
      cursor: pointer;
    }

    @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
      width: 100%;
      text-align: center;
      margin-top: 13px;
    }
  }

  @media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
    overflow-x: auto;
    width: 100%;
    display: block;
  }

  @media (max-width: #{$break-large}) and (min-width: #{$break-medium}) {
    .results-counter {
      position: static;
      margin-bottom: 10px;
    }
  }

  .ucop-error-list {
    &:focus {
      outline: none;
    }
    text-align: center;
    li {
      list-style-type: none;
    }
  }

  .spinner-container {
    text-align: center;

    .ucop-spinner div {
      background-color: $NHB_gray_3;
    }
  }
}

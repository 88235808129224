@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.leftside-menu {
  width: 14em;
  margin-right: 16px;
  height: 100%;
  margin-right: 30px;
  position: relative;
  min-width: 200px;
  max-width: 14em;
  & + .page-content {
    border-left: 1px solid $NHB_hilight_gray;
    @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
      border-left: none;
    }
  }
  ul {
    border: 1px solid $NHB_hilight_gray;
    h3 {
      background: $NHB_medium_gray;
      height: 35px;
      padding: 10px;
      font-family: $font-bold;
      font-size: 12pt;
      border-left: 1px solid $NHB_medium_gray;
      margin-bottom: 10px;
    }

    li {
      width: 95%;
      font-size: 12pt;
      padding: 5px;
      line-height: 25px;
      margin-bottom: 5px;
      border-bottom: 1px solid $NHB_medium_gray;
      margin-left: 5px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 10px;
      }
      &:hover {
        background: $NHB_medium_gray;
        cursor: pointer;
      }
      a {
        display: block;
        color: $NHB_black;
        text-decoration: none;
      }
    }
    li.active {
      background-color: $NHB_blue;
      color: $NHB_white;
      a {
        color: $NHB_white;
      }
    }
    .fa, .far {
      padding-right: 10px;
    }
  }
}
@media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
  .leftside-menu {
    width: 100%;
    min-height: auto;
    border-right: none;
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 5px;
      margin-left: 10px;
      border: none;
      border-bottom: 2px solid #eee;
      padding-bottom: 5px;
      width: 100%;
      padding-top: 0;

      li {
        padding: 5px 5px 5px 5px;
        text-align: center;
        height: 35px;
        margin-top: 10px;
        border: none;
        font-size: 10pt;
        max-width: 120px;
      }
      li.active {
        border-radius: 5px;
        a {
          color: $NHB_black;
        }
        i {
          color: $NHB_white;
        }
      }
    }
    h3 {
      display: none;
    }
  }
}

@charset "UTF-8";
@font-face {
  font-family: KievitProBook;
  src: url('fonts/KievitWebPro-Book.woff') format('woff'),
}
@font-face {
  font-family: KievitProRegular;
  src: url('fonts/KievitWebPro.woff') format('woff'),
}
@font-face {
  font-family: KievitProBld;
  src: url('fonts/KievitWebPro-Bold.woff') format('woff'),
}
@font-face {
  font-family: KievitProMed;
  src: url('fonts/KievitWebPro-Medi.woff') format('woff'),
}
@font-face {
  font-family: KievitProItal;
  src: url('fonts/KievitWebPro-BookIta.woff') format('woff'),
}


@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.search-results {
  h2 {
    text-align: center;
    font-size: 1.25em;
    margin-bottom: 22px;
    .search-term {
      font-family: $font-bold;
    }

  }

  .search-table {
    max-width: 100%;

    .search-result-issue-tag {
      padding: 5px 10px;
      background: $NHB_green_medium;
      color: $NHB_white;
      margin-left: 10px;
      border-radius: 10px;
    }

    .results-counter {
      bottom: 0;
      text-align: left;
      margin-bottom: 10px;
      padding-bottom: 12px;
      border-bottom: 2px solid $NHB_hilight_gray;
    }

    table {
      th {
        display: none;
      }

      tr {
        background-color: transparent;
        border-bottom: 1px solid $NHB_gray_5;
      }

      td {
        padding: 12px 0;
        font-size: 1.05em;
      }
    }

  }
}

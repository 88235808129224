////////////////////////////////////////////////
// BRAND COLORS ////////////////////////////////
////////////////////////////////////////////////
// PRIMARY COLORS
$uc_blue: #1295d8;
$uc_gold: #ffb511;

$blue: #005581;
$light_blue: #72cdf4;

$gold: #ffd200;
$light_gold: #ffe551;

// SECONDARY COLORS
$orange: #ff6e1b;
$light_orange: #ff8f28;

$pink: #e44c9a;
$light_pink: #feb2e0;

$teal: #00778b;
$light_teal: #00a3ad;

$uc_gray: #7c737f;
$warm_gray_8: #8f8884;
$warm_gray_3: #beb6af;
$warm_gray_1: #dbd5cd;

$metallic_gold: #b4975a;
$light_gray: #fafafa;

////////////////////////////////////////////////
// NHB COLORS //////////////////////////////////
////////////////////////////////////////////////
$NHB_dark_black: #000;
$NHB_black: #424242;
$NHB_overlay: rgba(0, 0, 0, 0.7);
$NHB_white: #ffffff;
$NHB_blue: #0c78b3;
$NHB_light_blue: #daf2ff;
$NHB_red: #e4463a; //I think it is a better red now :)
$NHB_light_gray: #fafafa;
$NHB_medium_gray: #eeeeee;
$NHB_hilight_gray: #e0e0e0;
$NHB_gray_1: #616161;
$NHB_gray_2: #757575;
$NHB_gray_3: #0c0b0b;
$NHB_gray_4: #fafafa;
$NHB_gray_5: #d8d8d8;
$NHB_green_light: #74c573;
$NHB_green_medium: #5b9c5a;
$NHB_green_dark: #008000;

@import "../../../styles/colors";
@import "../../../styles/fonts";
@import "../../../styles/media";

.tag-management {
  .fa-trash-alt {
    color: $warm_gray_8;
  }

  td {
    vertical-align: baseline;
  }

  button.tag-name {
    padding: 0 8px 0 0;
    border: none;
    background: none;
    font-family: $font-book;
    font-size: 100%;
    color: $NHB_black;

    &:hover {
      cursor: pointer;
      color: $NHB_blue;

      .fas {
        color: $NHB_blue;
      }
    }
  }

  .tag-name .fas {
    padding: 0;
    display: inline-block;
    position: relative;
    left: 6px;
    transition: transform 0.3s;
    transform: rotate(180deg);

    &.caret-down {
      transform: rotate(360deg);
    }
  }

  .category-title {
    margin-right: 4px;
  }

  .tag-issue-list {
    margin-left: 20px;
  }
}

.modal form.ucop-form-deletetag {
  h2 {
    color: $NHB_red;
  }

  p {
    margin: 10px 0 0 0;
    padding: 0 20px;
    line-height: 125%;
    text-align: center;
    font-size: 14pt;
  }

}

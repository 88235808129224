@import "../../../styles/fonts";
@import "../../../styles/widget-colors";

.ucop-text-input {
  display: block;
  margin: 15px 0 0 0;

  font-family: $font-bold;
  font-size: 12pt;

  color: $text_input_label;

  input {
    display: block;
    padding: 8px 16px;
    margin-top: 8px;
    margin-bottom: 10px;
    height: 45px;
    width: 100%;

    font-size: 12pt;

    border: 1px solid $text_input_border;
    border-radius: 4px;
    color: $text_input_fg;
    background-color: $text_input_bg;

    &:disabled {
      color: $text_input_fg_disabled;
      background-color: $text_input_bg_disabled;
    }
  }
}

@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.issue-title-container {
  padding: 30px 55px 0px 30px;
  background-color: $NHB_white;

  .issue-title {
    font-size: 26pt;
    font-weight: 700;
    border-bottom: 2px solid $NHB_gray_5;
    padding-bottom: 30px;

    .edit-section-button {
      position: relative;
      margin-left: 20px;
    }
  }
  &.edit .issue-title {
      display: none;
  }

  .issue-title-edit-container {
    border-bottom: 1px solid $NHB_gray_5;
  }

  .issue-title-edit-contents {
    background: $NHB_gray_4;
    border: 1px solid $NHB_medium_gray;
    padding: 15px;
  }

  .issue-form-fields {
    display: flex;
    width: 100%;

    .ucop-text-input,
    .ucop-select,
    .tag-selector-container {
      margin: 10px 15px 20px 0;
      width: 100%;
      font-size: 11pt;
      input,
      select {
        font-size: 10pt;
      }
    }
    .tag-selector-container {
      margin-right: 0;
    }
  }


  .tag-selector-container {
    label {
      font-size: 11pt;
      font-family: $font-bold;
      color: $NHB_black;
    }
    .tag-pillbottle {
      display: block;
      padding: 4px;
      margin-top: 8px;
      margin-bottom: 10px;
      min-height: 45px;
      max-height: 100px;
      overflow: scroll;
      width: 100%;
      border: 1px solid #dbd5cd;
      border-radius: 4px;
      color: #424242;
      background-color: #ffffff;
    }

    .tag-pill {
      border: 1px solid $NHB_blue;
      color: $NHB_black;
      background-color: $NHB_light_blue;
      display: inline-block;
      margin: 2px 4px;
      padding: 4px;
      border-radius: 8px;

      button {
        font-size: 8px;
        color: $NHB_blue;
        border: none;
        background: none;
        padding: 0;
        margin-left: 4px;
        transform: translateY(-1px);
        cursor: pointer;
        &:hover {
          color: $NHB_black;
        }
      }
    }

    .tag-picker-container {
      display: flex;
      align-items: baseline;
      position: absolute;
      transform: translateY(-5px);
      //@media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
        //position: static;
        //transform: none;
      //}
    }

    .tag-dropdown-container {
      width: 60%;
      margin-left: 2px;
      @media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
        width: auto;
      };
    }

    #tag-name-filter {
      width: 100%;
      border: 1px solid $NHB_gray_1;
    }

    .tag-list {
      border: 1px solid $NHB_gray_1;
      border-top: none;
      max-height: 250px;
      overflow: scroll;
      background-color: white;
    }

    .tag-list-item {
      background-color: white;
      padding: 6px 4px;
      cursor: pointer;
      &.selected, &:hover {
        background-color: $NHB_light_blue;
      }
    }

    .add-tag-button {
      border: none;
      color: $NHB_dark_black;
      background: none;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $NHB_blue;
      }
      &:disabled {
        color: $warm_gray_8;
        cursor: not-allowed;
      }
    }
  }

  .unpublished-issue-info {
    color: $NHB_gray_3;
    font-size: 14px;
    line-height: 150%;
    background: $NHB_white;
    padding: 20px;
    border-left: 2px solid $NHB_gray_3;
    border: 1px solid $NHB_medium_gray;
    min-width: 33em;
    .fa-exclamation-triangle {
      float: left;
      height: 70px;
      padding-top: 5px;
      margin-right: 20px;
    }
    p {
      padding-bottom: 5px;
      span {
        color: $metallic_gold;
        font-family: $font-med;
      }
    }
  }

  .issue-title-button-container {
    text-align: right;
  }

  .last-updated-info {
    display: block;
    text-align: right;
    font-family: $font-ital;
    font-size: 14px;
    color: $NHB_gray_2;
    margin: 5px 0;
  }


  .title-desc {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 200;
    color: $NHB_gray_1;
  }
}

.create-campus-guidance-button {
  display: block;
}

.version-control-container {
  width: 100%;
  text-align: center;

  .version-control {
    display: inline-block;
    border: 1px solid $warm_gray_1;
    background-color: $NHB_gray_4;
    margin: 20px;
    padding: 20px;

    span {
      font-weight: 900;
    }

    .version-control-buttons {
      display: flex;
      align-items: baseline;
      justify-content: center;
      flex-wrap: wrap;
    }

    a, a:visited {
      background: none;
      color: $NHB_blue;
      font-weight: 900;
      font-size: 16px;
      margin: 0 10px;
      &:hover {
        color: $blue;
      }
    }

    button {
      padding: 10px;
      margin: 10px 10px 0;
      font-size: 16px;
      background-color: $NHB_blue;
      color: white;
      height: auto;
      border-radius: 5px;
    }
  }
}

.issue-breadcrumbs {
  padding: 15px 30px;
  background-color: $NHB_white;
  border-bottom: 1px solid $NHB_gray_5;
  border-top: 1px solid $NHB_gray_5;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  .back-to-top {
    float: right;
    display: block;
  }
  a {
    text-decoration: none;
    &:visited {
      color: $NHB_blue;
    }
  }
  span,
  a {
    line-height: 30px;
    margin-right: 8px;
  }
}

.issue-view {
  .page-content {
    padding: 0 25px 0 25px;
    font-size: 12pt;
  }

  h1 {
    margin-bottom: 24px;
    @media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
      font-size: 16pt;
      line-height: 35px;
      padding-top: 30px;
    }
  }
}

.issue-section,
.issue-title-container {
  .save-section-button,
  .cancel-section-button {
    margin-left: 5px;
    height: auto;
    font-size: 11pt;
    padding: 5px 10px;
  }

  .edit-section-button {
    margin: 0;
    float: right;
    padding: 5px 10px;
    height: auto;
    background: $NHB_gray_4;
    color: $NHB_gray_2;
    border: 1px solid $NHB_gray_5;
    font-size: 11pt;
    &:hover {
      background: $NHB_blue;
      color: $NHB_white;
      border: 1px solid $uc_blue;
    }
  }

  .save-section-button,
  .cancel-section-button {
    margin-left: 5px;
    height: auto;
    font-size: 11pt;
    padding: 5px 10px;
  }
}

.issue-section {
  display: inline-block;
  width: 100%;

  .collapsed-section {
    display: none;
  }

  .issue-section-title {
    font-size: 18pt;
    display: inline-block;
    padding-bottom: 15px;
  }

  .action-btns {
    float: right;
  }
  .header-container {
    margin-bottom: 16pt;
    border-bottom: 2px solid $NHB_gray_5;
    padding-bottom: 6px;
    margin-top: 36pt;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .collapse-btn {
    margin: 0 12px;
    text-transform: uppercase;
    border: none;
    font-size: 16px;
    color: $NHB_blue;
    cursor: pointer;
    display: inline;
    background: none;
    padding: 0;
  }

  .previous-version-list {
    li {
      font-family: $font-ital;
      font-size: 14px;
      margin-bottom: 10px;
    }

  }

  .ucop-rich-text-editor {
    &.edit {
      .print-view {
        display: none;

      }
    }
    .print-view {
      line-height: 30px;
    }


    ol {
      display: block;
      list-style-type: decimal;
      margin-bottom: 1em;
      margin-left: 1em;
      line-height: 20pt;
    }

    p,
    span {
      font-size: 12pt;
      font-family: KievitProBook;
      line-height: 25px;
      padding: 5px 0;

      strong {
        font-family: KievitProBld;
      }

      @media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
        font-size: 11pt;
        line-height: 25px;
      }
    }
    strong {
      font-family: KievitProBld;
    }
    em {
      font-family: KievitProItal;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: KievitProBld;
      margin: 20px 0 20px 0;
    }
    h1 {
      font-size: 24pt;
    }
    h2 {
      font-size: 22pt;
    }
    h3 {
      font-size: 20pt;
    }
    h4 {
      font-size: 18pt;
    }
    h5 {
      font-size: 16pt;
    }
    h6 {
      font-size: 14pt;
    }
    ul,
    ol {
      li {
        margin-left: 20px;
      }
    }
  }
}

.issue-nav {
  float: left;
  width: 18em;
  margin-right: 16px;
  height: 100%;
  margin-right: 30px;
  position: relative;
  min-width: 200px;
  position: -webkit-sticky;
  position: sticky;
  top: 65px;

  & + .page-content {
    border-left: 1px solid $NHB_hilight_gray;
  }

  ul {
    color: $NHB_blue;
    border: 1px solid $NHB_hilight_gray;
    list-style: none;
    li {
      font-size: 12pt;
      padding: 5px 20px 5px 20px;
      line-height: 25px;
      border-bottom: 1px solid $NHB_medium_gray;
      a {
        text-decoration: none;
      }
    }
    li:hover:not(.issue-nav-sub-head) {
      background-color: $NHB_light_blue;
    }
  }

  .nav-active-section {
    background-color: $NHB_light_blue;
    :hover .nav-caret {
      visibility: visible;
    }
  }

  .previous-version-menu-item {
    border-top: 1px solid $NHB_gray_5;
  }
}

.nav-caret {
  float: right;
  vertical-align: text-top;
  line-height: 25px;
  visibility: hidden;
}

li.issue-nav-main:hover .nav-caret {
  visibility: visible;
}

.issue-nav-sub-head {
  font-size: 14px;
  padding: 0px 5px 0px 5px;
  background-color: $NHB_gray_5;
  color: $NHB_gray_1;
}

.issue-view {
  .issue-section {
    margin-bottom: 30px;
  }
  .campus-guidance {
    .campus-button-wrapper {
      display: flex;
      background: $NHB_light_gray;
      border: 1px dashed $NHB_gray_5;
      margin: 20px 0 40px 0;
      .btn-add-guidance {
        margin: 30px auto;
        font-size: 12pt;
      }
    }

    .issue-section {
      margin-bottom: 0;
    }
  }
}

// Back to Top
.back-to-top {
  float: right;
}
.back-to-top-deactivated {
  pointer-events: none;
  opacity: 0;
  transition: all 1s ease-out;
}
.back-to-top-activated {
  opacity: 100;
  transition: all 1s ease-out;
}

@media (max-width: #{$break-medium - 1}) and (min-width: #{$break-small}) {
  .issue-breadcrumbs {
    .back-to-top {
      position: relative;
      top: 89vh;
      right: -25px;
      background: #fff;
      padding: 2px 5px;
      border: 1px solid;
      border-radius: 5px;
      background: $uc_blue;
      width: 94vw;
      text-align: center;
      color: $NHB_white;
    }
  }
  .version-control-container {
    .version-control {
      width: 100%;
      margin: 0;
      padding: 10px;
    }
  }
  .issue-title-container .issue-form-fields {
    display: block;
  }
  .issue-title-container .unpublished-issue-info {
    min-width: 19em;
    margin: 30px 0 0 0;
  }
  .issue-title-container {
    padding: 0;
    background-color: #ffffff;
  }
  .issue-title-container .issue-title-edit-container {
    display: block;
  }
  .issue-title-container .issue-title-button-container {
    text-align: center;
    button {
      width: 25%;
    }
  }
  .issue-title-container .issue-title {
    padding: 20px;
  }
  .issue-nav {
    width: 100%;
    margin: 0;
    background: #fff;
    top: 48px;
    float: none;
    position: static;
  }
  .issue-view {
    .page-content {
      padding: 35px 0px 0 15px;
    }
  }
  .issue-section {
    .issue-section-title {
      font-size: 15pt;
      line-height: 25px;
      padding-top: 7px;
    }
  }
  .campus-guidance {
    .header-container {
      display: flex;
    }
  }
}

@media print {
  body {
    background-color: white !important;
    color: black !important;
  }

  .print-view {
    display: block !important;
  }

  .ucop-rich-text-editor.collapsed {
    display: block !important;
  }

  .app-header,
  .app-footer,
  .issue-breadcrumbs,
  .campus-button-wrapper,
  button,
  .issue-nav,
  .issue-title-edit-container,
  .tox-tinymce
  {
    display: none !important;
  }

  .page-content,
  .app-content,
  .issue-title-container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .issue-title-container.edit .issue-title {
    display: block !important;
  }

  .app-container {
    box-shadow: none !important;
    border: none !important;
    margin: 0.25in 0.5in !important;
  }

  .app-content {
    min-height: unset !important;
  }

  .page-content {
    border: none !important;
  }

  .issue-title {
    border: none !important;
    text-align: center !important;
  }
  .header-container {
    border-bottom: 1px solid black !important;
  }
}

@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.show-menu {
  text-align: center;
  background: $NHB_medium_gray;
  top: 60px;
  transition: opacity 1s ease-out;
  width: 155px;
  position: absolute;
  border: 1px solid $NHB_gray_5;
  border-radius: 3px;
  z-index: 2;
  ul {
    li {
      margin: 5px;
      padding: 4px;
      &:hover {
        background-color: $NHB_gray_4;
      }
      a {
        display: block;
        padding: 5px;
      }
    }
    li:not(:first-of-type) {
      margin: 5px;
      border-top: 1px solid $NHB_gray_1;
      padding: 4px;
    }
  }
}
.user-status {
  display: flex;

  &:hover {
    cursor: pointer;
  }
  span:hover {
    color: $NHB_blue;
  }
}

.rotate180 {
  animation-duration: 0.3s;
  animation-name: rotateUp180;
  animation-fill-mode: forwards;
  position: relative;
  top: 2px;
  left: 10px;
}

.unrotate180 {
  animation-duration: 0.3s;
  animation-name: rotateDown180;
  animation-fill-mode: forwards;
  position: relative;
  top: 2px;
  left: 10px;
}

.pr_1 {
  padding-right: 1em;
}
.profile-name {
  position: relative;
  top: 4px;
  cursor: pointer;
  left: -5px;
  font-size: 12pt;
  text-transform: capitalize;
  &:hover {
    color: $pink;
  }
}

.user-menu {
  display: flex;
  .user-role {
    margin: 0px 20px 0 0;
    font-size: 10pt;
    font-family: $font-book;
    background: $NHB_gray_5;
    padding: 10px 0 10px 0;
    width: 100%;
    color: $NHB_black;
    display: block;
  }
  .cross-menu {
    display: none;
    visibility: hidden;
  }

  .large-nav {
    visibility: visible !important;
    display: block;
  }
  .show-menu {
    .small-nav {
      display: none;
      visibility: hidden;
    }
  }
}

@keyframes rotateDown180 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes rotateUp180 {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.fa-user {
  border-radius: 30px;
  width: 25px;
  height: 25px;
  padding: 2px 5px 5px 5px;
  background: $NHB_medium_gray;
  position: relative;
  left: -10px;
  border: 1px solid $NHB_black;
}
.fa-edit,
.fa-cog,
.fa-sign-out-alt {
  padding-right: 10px;
  float: left;
  width: 5px;
}
@media (max-width: #{$break-medium - 1px}) and (min-width: #{$break-small - 1px}) {
  #menu {
    padding-top: 70px;
    width: 8em;
    margin: 0 auto;
  }
  .fa-edit,
  .fa-cog,
  .fa-sign-out-alt {
    padding-right: 10px;
    float: none;
    width: auto;
  }
  nav {
    background-color: transparent;
    position: absolute;
    width: 30px;
    height: 15px;
    z-index: 2;
    right: 20px;
    top: 25px;
  }
  .large-nav {
    display: none !important;
    visibility: hidden !important;
  }

  .show-menu {
    border: 0;
    height: auto;
    background-color: $NHB_gray_3;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-95vw);
    transition: transform 0.75s cubic-bezier(0.2, 0.2, 0.05, 1);
    text-align: center;
    top: -25px;
    width: 100vw;
    height: auto;
    min-height: 900px;
    left: 25px;
    z-index: auto;
    opacity: 0.96;

    ul li a {
      display: block;
      padding: 0px;
      color: $light_blue;
    }
    .small-nav {
      z-index: 3;
      width: inherit;
      visibility: visible !important;
      display: block !important;
      margin-top: 10em;

      .fa-user {
        background: white;
        border-radius: 35px;
        padding: 10px;
        width: 35px;
        height: 35px;
        margin-bottom: 15px;
      }

      .name {
        display: block;
        width: auto;
        color: $metallic_gold;
      }
    }
    .user-role {
      top: 80px;
      color: #424242;
      padding: 2px 5px;
      background: white !important;
      border-radius: 4px;
      display: table;
      margin: 0 auto;
    }
    .user-role.mobile-role {
      top: 20px;
      display: initial !important;
    }
  }
  .cross-menu {
    display: block;
    visibility: visible !important;
  }

  .user-menu .user-role {
    position: absolute;
    z-index: 3;
  }

  #menuToggle {
    input {
      width: 40px;
      height: 32px;
      position: absolute;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
    }
    span {
      display: flex;
      width: 29px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: #1e1e23;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 5px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }
    span:first-child {
      transform-origin: 0% 0%;
    }
    span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
    input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-3px, -1px);
      background: $NHB_medium_gray;
    }
    input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, -1px);
    }
    input:checked ~ ul {
      transform: none;
    }
  }
  #menu li {
    padding: 10px 0;
    transition-delay: 2s;
    margin: 0 auto;
    text-align: left;
  }
  .fa-user {
    left: 0;
  }
}

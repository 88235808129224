@import "../../styles/colors";
@import "../../styles/media";

.page-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: auto;
  @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
    flex-wrap: wrap;
  }
  @media (min-width: #{$break-medium}) and (max-width: #{$break-large - 1px}) {
    flex-wrap: nowrap;
  }
}

.page-content-children {
  p {
    line-height: 20pt;
    padding: 5px;
  }
  @media (min-width: #{$break-small}) and (max-width: #{$break-large - 1px}) {
    display: grid;
    padding: 0;
  }
}

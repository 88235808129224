@import "../../../styles/fonts";
@import "../../../styles/colors";

.ucop-button {
  margin: 15px 0;

  height: 40px;

  font-size: 14pt;
  font-family: $font;

  border: none;
  border-radius: 3px;

  color: $NHB_white;
  background-color: $NHB_blue;

  cursor: pointer;

  border-radius: 5px;
  &:hover {
    background-color: $blue;
  }
  &:disabled {
    cursor: not-allowed;
  }
}
